#quick_start {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  font-family: sans-serif;
  color: #ffffff;
  z-index: 999;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  text-align: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  top: 0;
}

#quick_start img {
  width: 20%;
  vertical-align: middle;
  margin-right: 15px;
}

#quick_start span {
  display: inline-block;
}

#quick_start div {
  margin-top: 30px;
}

#hide {
  padding: 10px 15px 10px 15px;
  border-radius: 7px;
  border-width: 3px;
  font-size: 20px;
  box-shadow: none;
  border-style: solid;
  border-color: #ffffff;
  color: #ffffff;
  position: absolute;
  bottom: 0px;
}
