* {
  /* background-color: <%= color_code %>; */
  color: white;
  font-family: sans-serif;
  text-align: center;
}
.heading {
  font-size: 20px;
  margin-top: 70px;
}
.msg {
  margin-top: 20px;
}
img {
  width: 85%;
  margin-top: 70px;
}
