html,
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#renderCanvas {
  width: 100%;
  height: 100%;
  touch-action: none;
}

div#btnId {
  padding: 10px;
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  width: fit-content;
  word-wrap: none;
  word-break: keep-all;
}
