.place-delivery {
    background-color:transparent;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 95%;
    color: white;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.place-delivery-img-container {
    display: flex;
    justify-content: center;
    max-height: 10%;
}

.place-delivery-img-container img{
    max-width: 10%;
    max-height: 10%;
    display: flex;
    margin: 5px;
}